<template>
  <el-dialog
    top="10vh"
    width="95%"
    visible
    :show-close="false"
    :before-close="close"
    custom-class="rounded payment-operation-modal"
    :class="{ ['darker-mask']: isInnerModalOpen }"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <h2>
          {{ $t(`payment.paymentOperationModal.${isExecution ? 'execution' : 'documentation'}`) }}
        </h2>
        <Button type="icon" class="p-0" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="d-flex flex-column justify-content-between h-100">
      <div v-if="loading" v-loading="loading" class="h-100 w-100" />
      <el-form v-else ref="form" :model="{ imbalances }" :show-message="false" class="overflow-auto">
        <Table
          :data="tableData"
          :columns="columns"
          :hover="false"
          show-index
          border
          rounded
          class="h-100 w-100"
          custom-class="payment-operation-table"
          :cell-class="getCellStyle"
          @header-action="handleHeaderActions"
        >
          <template #header-paymentDueDate="{ column: { header } }">
            <div class="lh-1">
              {{ header }}
              <div>
                <small class="text-muted">
                  {{ $t('payment.paymentOperationModal.table.headers.accordingToPaymentTerms') }}
                </small>
              </div>
            </div>
          </template>
          <template #cell-paymentDueDate="{ rowData: { paymentDueDate } }">
            <div>
              {{ formatDate(paymentDueDate) }}
            </div>
          </template>

          <template #cell-totalBilled="{ rowData: { totalBilled, billedAmounts } }">
            <div class="d-flex align-items-start flex-column">
              <p class="lh-1">
                {{ formatMoney(totalBilled) }}
              </p>
              <small class="text-muted">
                {{
                  $tc('payment.paymentOperationModal.table.data.billings', billedAmounts.length, {
                    count: billedAmounts.length,
                  })
                }}
              </small>
            </div>
          </template>

          <template #cell-balanceDue="{ rowData: { balanceDue, billingsLeftToBePaid } }">
            <div class="d-flex align-items-start flex-column">
              <p class="lh-1">
                {{ formatMoney(balanceDue) }}
              </p>
              <small class="text-muted">
                {{
                  $tc('payment.paymentOperationModal.table.data.billings', billingsLeftToBePaid.length, {
                    count: billingsLeftToBePaid.length,
                  })
                }}
              </small>
            </div>
          </template>

          <template v-if="isExecution" #actions-amounts>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="chooseAllFullPaymentAmount">
                <div class="d-flex gap-1 align-items-center">
                  <CompleteIcon />
                  <p>{{ $t('payment.paymentOperationModal.table.headerActions.amountToPay') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="!hideFullPaymentPlusCredits" command="chooseAllFullPaymentAmountPlusCredits">
                <div class="d-flex gap-1 align-items-center">
                  <CompleteIcon />
                  <p>{{ $t('payment.paymentOperationModal.table.headerActions.amountToPayPlusCredits') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided command="resetAllPaymentAmount">
                <div class="d-flex gap-1 align-items-center">
                  <ResetIcon />
                  <p>{{ $t('commons.reset') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
          <template
            #cell-amounts="{
              rowData: { amounts, status, billingsLeftToBePaid, supplierId, unpaidCredits, reconciliationId },
              rowIndex,
            }"
          >
            <el-form-item :prop="`imbalances.${rowIndex}.amountsToBePaid`" :rules="{ required: true }" class="m-0">
              <el-dropdown trigger="click" class="w-100 d-flex" @command="handlePaymentAmount">
                <Button
                  type="text"
                  size="medium"
                  class="w-100 fw-normal p-0"
                  :disabled="status === 'processed'"
                  :class="{ 'p-0': amounts.length }"
                >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <div v-if="amounts?.length" class="d-flex align-items-start flex-column">
                      <p>
                        {{ formatMoney(amounts.reduce((sum, { amount }) => sum + amount, 0)) }}
                      </p>
                      <small style="line-height: var(--bs-body-line-height)">
                        {{
                          $tc('payment.paymentOperationModal.table.data.billings', amounts.length, {
                            count: amounts.length,
                          })
                        }}
                      </small>
                    </div>
                    <div v-else>{{ $t('commons.select') }}...</div>
                    <ChevronIcon direction="down" />
                  </div>
                </Button>
                <el-dropdown-menu>
                  <el-tooltip
                    popper-class="custom-tooltip"
                    :disabled="billingsLeftToBePaid.length !== 0"
                    placement="top"
                    :content="$t('payment.paymentOperationModal.unbilledOrdersWithoutInvoicesOpened')"
                    ><span>
                      <el-dropdown-item
                        :command="{ action: 'fullAmount', rowIndex, billingsLeftToBePaid, reconciliationId }"
                        :disabled="!billingsLeftToBePaid.length"
                      >
                        <CompleteIcon />
                        <span class="mx-1">
                          {{
                            fullAmountPlusCreditsFromReconciliationMonthText({ billingsLeftToBePaid, reconciliationId })
                          }}
                        </span>
                      </el-dropdown-item></span
                    ></el-tooltip
                  >

                  <el-dropdown-item
                    v-if="unpaidCredits.length"
                    :disabled="!isSupplierImbalanceSingle(supplierId)"
                    :command="{
                      action: 'fullAmountPlusCredits',
                      rowIndex,
                      billingsLeftToBePaid,
                      unpaidCredits,
                      reconciliationId,
                    }"
                  >
                    <CompleteIcon />
                    <span class="mx-1">
                      {{
                        fullAmountPlusCreditsFromMonthsText({ unpaidCredits, billingsLeftToBePaid, reconciliationId })
                      }}
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item :command="{ action: 'define', rowIndex }">
                    <SettingsIcon />
                    <span class="mx-1">
                      {{ $t('payment.paymentOperationModal.table.define') }}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </template>

          <template v-if="isExecution" #actions-paymentDate>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="executeToday" :disabled="paymentsToBePaid.length === 0">
                <div class="d-flex gap-1 align-items-center">
                  <CalendarDayIcon />
                  <p>{{ $t('payment.paymentOperationModal.table.headerActions.paymentExecutionDateToday') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                command="executeAccordingToPaymentTerms"
                :disabled="!hasDueDateTerms || paymentsToBePaid.length === 0"
              >
                <div class="d-flex gap-1 align-items-center">
                  <CalendarTermIcon />
                  <p>{{ $t('payment.paymentOperationModal.table.headerActions.paymentExecutionDateByDueDate') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="choosePaymentDateToAll" :disabled="paymentsToBePaid.length < 2">
                <div class="d-flex gap-1 align-items-center">
                  <CalendarIcon />
                  <p>{{ $t('payment.paymentOperationModal.table.headerActions.paymentExecutionDateModal') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided command="resetAllPaymentDate" :disabled="paymentsToBePaid.length === 0">
                <div class="d-flex gap-1 align-items-center">
                  <ResetIcon />
                  <p>{{ $t('commons.reset') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
          <template #cell-paymentDate="{ rowData: { status, paymentDueDate, details, amounts }, rowIndex }">
            <el-tooltip
              effect="dark"
              placement="top"
              :disabled="paymentsBalance[rowIndex] !== 0 || !amounts.length"
              :content="$t('payment.paymentOperationModal.table.tooltips.balancedPayment')"
            >
              <el-form-item
                :prop="`imbalances.${rowIndex}.paymentDate`"
                :rules="{ required: true }"
                class="mb-0 payment-date"
              >
                <el-date-picker
                  v-model="imbalances[rowIndex].paymentDate"
                  :disabled="status === 'processed' || paymentsBalance[rowIndex] === 0"
                  size="small"
                  type="date"
                  :format="$direction === 'ltr' ? 'MM.dd.yy (dddd)' : 'dd.MM.yy (יום ddd)'"
                  class="payment-date-picker"
                  :picker-options="getDatePickerOptions(paymentDueDate, details)"
                  :placeholder="`${$t('commons.select')}...`"
                />
              </el-form-item>
            </el-tooltip>
          </template>

          <template v-if="isExecution" #actions-paymentMethod>
            <el-dropdown-menu slot="dropdown" @command="handlePaymentMethodAdd">
              <el-dropdown-item
                :disabled="!hasSupplierBankAccounts || paymentsToBePaid.length === 0"
                command="chooseAllPaymentMethod"
              >
                <div class="d-flex gap-1 align-items-center">
                  <BankTransferIcon />
                  <p>{{ $t('payment.paymentOperationModal.bankTransferToSupplierAccount') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided command="resetAllPaymentMethod" :disabled="paymentsToBePaid.length === 0">
                <div class="d-flex gap-1 align-items-center">
                  <ResetIcon />
                  <p>{{ $t('commons.reset') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
          <template
            #cell-paymentMethod="{
              rowData: { amounts, paymentMethod, supplierBankAccount, paymentTerm, status },
              rowIndex,
            }"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top"
              :disabled="paymentsBalance[rowIndex] !== 0 || !amounts.length"
              :content="$t('payment.paymentOperationModal.table.tooltips.balancedPayment')"
            >
              <el-form-item
                :prop="`imbalances.${rowIndex}.transactions`"
                :rules="{ required: paymentsBalance[rowIndex] === 0 ? false : true }"
                class="m-0"
              >
                <el-dropdown v-if="isExecution" trigger="click" class="w-100" @command="handlePaymentMethodAdd">
                  <Button
                    size="medium"
                    type="text"
                    :disabled="status === 'processed' || paymentsBalance[rowIndex] === 0"
                    class="p-0 w-100 fw-normal"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <div v-if="paymentMethod" class="text-wrap lh-1">
                        <BankTransferIcon />
                        {{ $t(`payment.exports.paymentMethods.fullName.${paymentMethod}`) }}
                      </div>
                      <div v-else>{{ `${$t('commons.select')}...` }}</div>
                      <ChevronIcon direction="down" />
                    </div>
                  </Button>
                  <el-dropdown-menu>
                    <el-tooltip
                      :disabled="!!supplierBankAccount"
                      effect="dark"
                      placement="top"
                      popper-class="custom-tooltip"
                      :content="$t('payment.paymentOperationModal.suppplierBankAccountNotConfigured')"
                      ><span
                        ><el-dropdown-item
                          :command="{ action: 'choosePaymentMethod', rowIndex }"
                          :disabled="!supplierBankAccount"
                        >
                          <BankTransferIcon />
                          {{ $t('payment.paymentOperationModal.bankTransferToSupplierAccount') }}
                        </el-dropdown-item>
                      </span>
                    </el-tooltip>
                    <div class="d-flex justify-content-between mx-1">
                      <el-dropdown-item
                        :command="{ action: 'addPaymentMethod', rowIndex }"
                        :disabled="!hasPaymentOtherDestination"
                      >
                        <MoreIcon /> {{ `${$t('commons.other')}...` }}
                      </el-dropdown-item>
                      <el-tooltip
                        :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
                        placement="top"
                        ><span><LockInvertedIcon v-if="!hasPaymentOtherDestination" class="m-4" /></span
                      ></el-tooltip>
                    </div>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown
                  v-else-if="canShowPaymentMethodQuickAction(amounts, paymentTerm)"
                  trigger="click"
                  class="w-100"
                  @command="handlePaymentMethodAdd"
                >
                  <Button
                    :disabled="status === 'processed' || paymentsBalance[rowIndex] === 0"
                    type="text"
                    size="medium"
                    class="p-0 w-100 fw-normal"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <div v-if="paymentMethod">
                        <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER"><BankTransferIcon /> </span>
                        <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE"><ChequeIcon /> </span>
                        <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD"><CreditCardIcon /> </span>
                        <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CASH"><CashIcon /> </span>
                        {{ $t(`payment.exports.paymentMethods.fullName.${paymentMethod}`) }}
                      </div>
                      <div v-else>{{ `${$t('commons.select')}...` }}</div>
                      <ChevronIcon direction="down" />
                    </div>
                  </Button>
                  <el-dropdown-menu>
                    <template v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD">
                      <el-dropdown-item
                        v-for="(creditCard, index) in tenantCreditCards"
                        :key="index"
                        :command="{ action: 'choosePaymentMethod', rowIndex, creditCard, paymentTerm }"
                      >
                        <CreditCardIcon />
                        {{
                          `${$t(`payment.paymentMethodModal.labels.${creditCard.type}`)} ${creditCard.number} - ${$t(
                            'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
                          )}`
                        }}
                      </el-dropdown-item>
                    </template>
                    <template v-if="paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER">
                      <el-dropdown-item :command="{ action: 'choosePaymentMethod', rowIndex, paymentTerm }">
                        <BankTransferIcon />
                        {{
                          `${$t('payment.exports.paymentMethods.fullName.bankTransfer')} - ${$t(
                            'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
                          )}`
                        }}
                      </el-dropdown-item>
                    </template>
                    <el-dropdown-item :command="{ action: 'addDocumentedPaymentMethod', rowIndex }">
                      <MoreIcon /> {{ `${$t('commons.other')}...` }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <Button
                  v-else
                  :disabled="status === 'processed' || paymentsBalance[rowIndex] === 0"
                  type="text"
                  size="medium"
                  class="p-0 w-100 fw-normal"
                  @click="selectedImbalanceTransactionIndex = rowIndex"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div v-if="paymentMethod">
                      <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER"><BankTransferIcon /> </span>
                      <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE"><ChequeIcon /> </span>
                      <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD"><CreditCardIcon /> </span>
                      <span v-if="paymentMethod === PAYMENT_METHOD_TYPE.CASH"><CashIcon /> </span>
                      {{ $t(`payment.exports.paymentMethods.fullName.${paymentMethod}`) }}
                    </div>
                    <div v-else>{{ `${$t('commons.select')}...` }}</div>
                    <ChevronIcon direction="down" />
                  </div>
                </Button> </el-form-item
            ></el-tooltip>
          </template>

          <template #cell-details="{ rowData: { paymentMethod, directDebit, details } }">
            <div v-if="details" class="d-flex flex-column">
              <template v-if="paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER && !directDebit">
                <template v-if="details.destinationBankAccount">
                  <p class="lh-1">
                    {{
                      `${$t('payment.paymentOperationModal.table.data.supplierAccount')} ${
                        details.destinationBankAccount.accountNumber
                      }`
                    }}
                  </p>
                  <small v-if="details.destinationBankAccount.bankNumber && countryCode === 'IL'" class="text-muted">{{
                    `${supportedBanks[details.destinationBankAccount.bankNumber]}, ${$t(
                      'payment.paymentOperationModal.table.data.bankBranch'
                    )} ${details.destinationBankAccount.branchNumber}`
                  }}</small>
                  <small
                    v-else-if="details.destinationBankAccount.bankNumber && countryCode === 'US'"
                    class="text-muted"
                    >{{ `${details.destinationBankAccount.routingNumber}` }}</small
                  >
                  <small v-else class="text-muted">-</small>
                </template>
                <p v-else class="text-muted">-</p>
              </template>
              <template v-if="paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER && directDebit">
                <p class="lh-1">
                  {{
                    `${$t(`payment.exports.paymentMethods.fullName.${paymentMethod}`)} - ${$t(
                      'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
                    )}`
                  }}
                </p>
              </template>
              <template v-if="paymentMethod === PAYMENT_METHOD_TYPE.CHEQUE">
                <template v-if="details.number && details.dueDate">
                  <p class="lh-1">
                    {{ `${$t('payment.paymentMethodModal.labels.chequeNumber')} ${details.number}` }}
                  </p>
                  <small class="text-muted">{{ formatDate(details.dueDate) }}</small>
                </template>
                <template v-else>
                  <small class="text-muted">-</small>
                </template>
              </template>
              <template v-if="paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD">
                <p v-if="details.type && details.number" class="lh-1">
                  {{ `${$t(`payment.paymentMethodModal.labels.${details.type}`)} ${details.number}` }}
                  <span v-if="details.directDebit === true">
                    - {{ $t('terms.supplierTerms.generalTerms.paymentMethodModal.directDebit') }}
                  </span>
                </p>
                <p v-else>-</p>
                <small class="text-muted">{{ details.cardOwner }}</small>
              </template>
            </div>
            <small v-else class="text-muted">-</small>
          </template>
          <template #cell-status="{ rowData: { status } }">
            <template v-if="status && status !== 'unprocessed'">
              <ApproveIcon v-if="status === 'processed'" class="text-success" />
              <CloseIcon v-else-if="status === 'failed'" class="text-danger" />
              <el-tooltip
                v-else-if="status === 'conflict'"
                class="item"
                effect="dark"
                placement="top"
                :content="$t('payment.paymentOperationModal.paymentToExecuteCreateDuplicateMessage')"
              >
                <CloseIcon class="text-warning" />
              </el-tooltip>
            </template>
            <div v-else />
          </template>

          <template v-if="tableData.length > 1" #cell-actions="{ rowIndex }">
            <el-dropdown
              class="d-flex justify-content-center remove-action"
              trigger="click"
              placement="bottom"
              @command="handleAction($event, rowIndex)"
              @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
            >
              <Button type="icon" :class="{ active: activeRowActionsIndex === rowIndex }">
                <KebabIcon />
              </Button>
              <el-dropdown-menu>
                <el-dropdown-item command="removeRow">
                  <div class="d-flex align-items-center gap-2">
                    <TrashCanIcon />
                    <p>
                      {{ $t('commons.remove') }}
                    </p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </Table>
      </el-form>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-center border rounded gap-1">
          <div class="d-flex align-items-center px-4 py-2 bg-secondary h-100 gap-2">
            <small class="text-muted">
              {{ $t(`payment.paymentOperationModal.footer.${isExecution ? 'totalToBePaid' : 'totalPaid'}`) }}
            </small>
            <h3 class="text-typography-primary">{{ formatMoney(totalAmountPaid) }}</h3>
          </div>
          <div class="d-flex align-items-center p-2">
            <Button type="secondary" @click="close">{{ $t('commons.cancel') }}</Button>
            <Button
              :disabled="loading"
              :type="isExecution ? 'success' : 'primary'"
              :class="$direction === 'ltr' ? 'me-1' : 'ms-1'"
              @click="handleSaveButtonClick"
            >
              {{ submitText }}
            </Button>
          </div>
        </div>
      </div>
    </template>
    <BillingsToPaySelectionModal
      v-if="selectedImbalancePaidBillingsIndex !== null"
      visible
      :is-execution="isExecution"
      :billing-ids="imbalances[selectedImbalancePaidBillingsIndex].billedAmounts.map(({ billingId }) => billingId)"
      :payment-ids="imbalances[selectedImbalancePaidBillingsIndex].paidAmounts.map(({ paymentId }) => paymentId)"
      :supplier="imbalances[selectedImbalancePaidBillingsIndex].supplier"
      :reconciliation-id="imbalances[selectedImbalancePaidBillingsIndex].reconciliationId"
      :selected-billings="selectedBillings"
      @apply="handleBillingsToPaySelection"
      @close="selectedImbalancePaidBillingsIndex = null"
    />
    <PaymentMethodModal
      v-if="selectedImbalanceTransactionIndex !== null"
      :supplier-account="imbalances[selectedImbalanceTransactionIndex].supplierBankAccount"
      :credit-cards="tenantCreditCards"
      @close="selectedImbalanceTransactionIndex = null"
      @paymentMethodUpdate="handlePaymentMethodUpdate"
    />
    <AddPaymentMethodModal
      v-if="selectedImbalancePaymentMethodIndex !== null"
      :supplier-name="imbalances[selectedImbalancePaymentMethodIndex].supplier.name"
      @close="selectedImbalancePaymentMethodIndex = null"
      @updatePaymentMethod="handleAddPaymentMethod"
    />
    <ExecutionDateModal
      v-if="quickActionDatePickerModal"
      :date-picker-options="getDatePickerOptions"
      @close="quickActionDatePickerModal = false"
      @submit="updateAllPaymentDate"
    />
  </el-dialog>
</template>

<script>
import { ref, computed, inject, onBeforeUnmount } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil, omit, uniq, flatten, isEmpty, groupBy, map, reduce, equals, pipe, values } from 'ramda';
import Big from 'big.js';

import {
  ChevronIcon,
  CloseIcon,
  CreditCardIcon,
  ChequeIcon,
  BankTransferIcon,
  ApproveIcon,
  MoreIcon,
  CashIcon,
  CompleteIcon,
  ResetIcon,
  CalendarIcon,
  CalendarTermIcon,
  CalendarDayIcon,
  TrashCanIcon,
  KebabIcon,
  SettingsIcon,
} from '@/assets/icons';
import { Table, Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { AddPaymentMethodModal } from '@/modules/suppliers';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useGlobalPermissions } from '@/modules/permissions';
import { LockInvertedIcon } from '@/assets/icons';

import { formatDate } from '../../tools/formatters';
import { PAYMENT_METHOD_TYPE } from '../../types';
import {
  useCreditCards,
  usePaymentTerms,
  useUnpaidBillings,
  PAYMENT_PAGE_CONTEXT,
  useBankAccounts,
  useSupportedBanks,
} from '../../compositions';
import BillingsToPaySelectionModal from './BillingsToPaySelectionModal';
import PaymentMethodModal from './PaymentMethodModal';
import ExecutionDateModal from './ExecutionDateModal';

const TABLE_HEADERS = {
  SUPPLIER_NAME: 'supplierName',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  TOTAL_BILLED: 'totalBilled',
  BALANCE_DUE: 'balanceDue',
  AMOUNTS: 'amounts',
  PAYMENT_METHOD: 'paymentMethod',
  PAYMENT_DATE: 'paymentDate',
  DETAILS: 'details',
  STATUS: 'status',
};

const timeoutIds = [];

export default {
  components: {
    Table,
    Button,
    BillingsToPaySelectionModal,
    PaymentMethodModal,
    ChevronIcon,
    CloseIcon,
    AddPaymentMethodModal,
    BankTransferIcon,
    CreditCardIcon,
    ChequeIcon,
    MoreIcon,
    ApproveIcon,
    CashIcon,
    CompleteIcon,
    ResetIcon,
    CalendarIcon,
    TrashCanIcon,
    KebabIcon,
    CalendarTermIcon,
    CalendarDayIcon,
    ExecutionDateModal,
    SettingsIcon,
    LockInvertedIcon,
  },
  props: {
    paymentImbalances: { type: Array, default: () => [] },
    isExecution: { type: Boolean, default: false },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const countryCode = computed(() => currentTenant.value?.countryCode);
    const { supportedBanks } = useSupportedBanks(countryCode);
    const { terms: paymentTerms } = usePaymentTerms(
      computed(() => ({ businessId: currentTenant.value.id })),
      computed(() => ({ enabled: !!currentTenant.value.id }))
    );
    const { hasPaymentOtherDestination } = useGlobalPermissions();
    const imbalances = ref(
      props.paymentImbalances.map(
        ({ id, supplier, billedAmounts, paymentDueDate, paidAmounts, billingsLeftToBePaid }) => {
          const { bankAccounts } = useBankAccounts(ref(supplier.id));
          const paymentTerm = computed(() => paymentTerms.value.find((term) => term.supplierId === supplier.id));
          const supplierBankAccount = computed(() =>
            bankAccounts.value.find(({ id }) => id === paymentTerm.value?.bankAccountId)
          );
          return {
            reconciliationId: id,
            supplier,
            billingsLeftToBePaid,
            paymentDueDate: new Date(paymentDueDate),
            paidAmounts,
            amountsToBePaid: [],
            billedAmounts,
            transactions: [],
            paymentDate: null,
            paymentTerm,
            supplierBankAccount,
            status: 'unprocessed',
          };
        }
      )
    );
    const { creditCards } = useCreditCards(computed(() => currentTenant.value.id));

    const { createPayment, createPaymentLoading } = inject(PAYMENT_PAGE_CONTEXT);

    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: currentTenant.value.id }))
    );

    const imbalanceCreditIdsBySupplier = computed(() =>
      imbalances.value.reduce((supplierMap, imbalance) => {
        if (!supplierMap[imbalance.supplier.id]) supplierMap[imbalance.supplier.id] = [];
        const billingIds = imbalance.billedAmounts.reduce((credits, { billingId, amount }) => {
          if (Math.sign(amount) === -1) credits.push(billingId);
          return credits;
        }, []);
        supplierMap[imbalance.supplier.id] = supplierMap[imbalance.supplier.id].concat(billingIds);
        return supplierMap;
      }, {})
    );

    const unpaidCreditsBySupplier = computed(() =>
      unpaidBillings.value.reduce((unpaidBySupplier, unpaidBilling) => {
        const { totalAmount, billingId, supplierId } = unpaidBilling;
        const isCredit = Math.sign(totalAmount) === -1;
        const isNotPartOfImbalance = !imbalanceCreditIdsBySupplier.value[supplierId]?.some(
          (imbalanceCreditId) => imbalanceCreditId === billingId
        );
        const isSupplierInImbalances = imbalances.value.some((imbalance) => imbalance.supplier.id === supplierId);
        if (isCredit && isNotPartOfImbalance && isSupplierInImbalances) {
          if (!unpaidBySupplier[supplierId]) unpaidBySupplier[supplierId] = [];
          unpaidBySupplier[supplierId].push(unpaidBilling);
        }
        return unpaidBySupplier;
      }, {})
    );

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    const paymentsBalance = computed(() =>
      map(
        ({ amountsToBePaid }) =>
          reduce((sum, { amount }) => sum.plus(new Big(amount)), new Big(0), amountsToBePaid).toNumber(),
        imbalances.value
      )
    );

    const paymentsToBePaid = computed(() => reject(equals(0), paymentsBalance.value));

    const singleCredits = computed(() =>
      imbalances.value.reduce((acc, { reconciliationId, billingsLeftToBePaid }) => {
        if (billingsLeftToBePaid.length === 1 && billingsLeftToBePaid[0].amount < 0) {
          acc[reconciliationId] = [billingsLeftToBePaid[0].billingId];
        }
        return acc;
      }, {})
    );

    return {
      hasPaymentOtherDestination,
      paymentsBalance,
      paymentsToBePaid,
      formatMoney,
      countryCode,
      supportedBanks,
      PAYMENT_METHOD_TYPE,
      currentTenant,
      createPayment,
      loading: computed(() => createPaymentLoading.value || unpaidBillingsLoading.value),
      selectedImbalanceTransactionIndex: ref(null),
      selectedImbalancePaidBillingsIndex: ref(null),
      selectedImbalancePaymentMethodIndex: ref(null),
      quickActionDatePickerModal: ref(null),
      imbalances,
      formatDate,
      tenantCreditCards: creditCards,
      activeRowActionsIndex: ref(-1),
      paymentTerms,
      unpaidCreditsBySupplier,
      singleCredits,
      selectedBillings: ref(singleCredits.value),
    };
  },
  computed: {
    submitText() {
      if (!this.isExecution) return this.$t('commons.save');

      const sum = reduce((acc, n) => acc.plus(n), new Big(0));
      const totalPaymentsSum = sum(map(Big, this.paymentsToBePaid)).toNumber();
      const hasSelectedPayments = this.imbalances.some((i) => i.amountsToBePaid.length);
      if (hasSelectedPayments && totalPaymentsSum === 0) return this.$t('payment.paymentOperationModal.approve');

      return this.$t('payment.paymentOperationModal.transferToBank');
    },
    tableData() {
      return this.imbalances.map((imbalance) => {
        const {
          supplier,
          paidAmounts,
          billedAmounts,
          transactions,
          amountsToBePaid,
          paymentDueDate,
          paymentDate,
          paymentTerm,
          supplierBankAccount,
          billingsLeftToBePaid,
          status,
          reconciliationId,
        } = imbalance;

        const billingsToOmit = this.filterOutCurrentRecoSelectedBillings(reconciliationId);
        return {
          supplierId: supplier.id,
          [TABLE_HEADERS.SUPPLIER_NAME]: supplier.name,
          [TABLE_HEADERS.PAYMENT_DUE_DATE]: paymentDueDate,
          [TABLE_HEADERS.AMOUNTS]: amountsToBePaid,
          [TABLE_HEADERS.BALANCE_DUE]: billingsLeftToBePaid.reduce((sum, { balanceDue }) => sum + balanceDue, 0),
          [TABLE_HEADERS.TOTAL_BILLED]: billedAmounts.reduce((sum, { amount }) => sum + amount, 0),
          [TABLE_HEADERS.PAYMENT_METHOD]: transactions.length ? transactions[0].paymentMethod : null,
          directDebit: transactions.length ? transactions[0].directDebit : null,
          [TABLE_HEADERS.PAYMENT_DATE]: paymentDate,
          [TABLE_HEADERS.DETAILS]: transactions.length ? transactions[0] : null,
          [TABLE_HEADERS.STATUS]: status,
          unpaidCredits: this.unpaidCreditsBySupplier[supplier.id]
            ? this.unpaidCreditsBySupplier[supplier.id].filter((credit) => !billingsToOmit.includes(credit))
            : [],
          paidAmounts,
          billedAmounts,
          supplierBankAccount,
          billingsLeftToBePaid,
          paymentTerm,
          reconciliationId,
        };
      });
    },
    totalAmountPaid() {
      return this.imbalances.reduce(
        (sum, { amountsToBePaid }) =>
          sum + amountsToBePaid.reduce((amountsToBePaidSum, { amount }) => amountsToBePaidSum + amount, 0),
        0
      );
    },
    columns() {
      return [
        {
          header: this.$t('payment.paymentOperationModal.table.headers.supplier'),
          key: TABLE_HEADERS.SUPPLIER_NAME,
          width: '120px',
        },
        {
          header: this.$t('payment.paymentOperationModal.table.headers.paymentDueDate'),
          key: TABLE_HEADERS.PAYMENT_DUE_DATE,
          width: '110px',
        },
        {
          header: this.$t('payment.paymentOperationModal.table.headers.totalBilled'),
          key: TABLE_HEADERS.TOTAL_BILLED,
          width: '113px',
        },
        {
          header: this.$t('payment.paymentOperationModal.table.headers.balanceDue'),
          key: TABLE_HEADERS.BALANCE_DUE,
          width: '113px',
        },
        {
          header: this.$t(
            `payment.paymentOperationModal.table.headers.${this.isExecution ? 'amountToPay' : 'paidAmount'}`
          ),
          key: TABLE_HEADERS.AMOUNTS,
          width: '113px',
        },
        {
          header: this.$t(
            `payment.paymentOperationModal.table.headers.${this.isExecution ? 'paymentExecutionDate' : 'paymentDate'}`
          ),
          key: TABLE_HEADERS.PAYMENT_DATE,
          width: '192px',
        },
        {
          header: this.$t(
            `payment.paymentOperationModal.table.headers.${this.isExecution ? 'paymentMethod' : 'paidWith'}`
          ),
          key: TABLE_HEADERS.PAYMENT_METHOD,
          width: '160px',
        },
        {
          header: this.$t('payment.paymentOperationModal.table.headers.paymentDetails'),
          key: TABLE_HEADERS.DETAILS,
          width: '160px',
        },
        {
          key: TABLE_HEADERS.STATUS,
          width: '26px',
        },
        {
          key: 'actions',
          width: '38px',
        },
      ];
    },
    isInnerModalOpen() {
      return (
        typeof this.selectedImbalancePaidBillingsIndex === 'number' ||
        typeof this.selectedImbalanceTransactionIndex === 'number' ||
        this.quickActionDatePickerModal
      );
    },

    hasSupplierBankAccounts() {
      return this.imbalances.some((i) => i.supplierBankAccount && i.status !== 'processed');
    },
    hasDueDateTerms() {
      return this.imbalances.some((i) => i.paymentDueDate && i.paymentDueDate > new Date() && i.status !== 'processed');
    },
    hideFullPaymentPlusCredits() {
      const suppliersImbalances = this.imbalances.reduce((supplierMap, imbalance) => {
        if (!supplierMap[imbalance.supplier.id]) supplierMap[imbalance.supplier.id] = 0;
        supplierMap[imbalance.supplier.id] += 1;
        return supplierMap;
      }, {});
      const noAdditionalCredits = this.imbalances.every(
        (imbalance) => !this.unpaidCreditsBySupplier[imbalance.supplier.id]
      );
      return Object.values(suppliersImbalances).every((count) => count > 1) || noAdditionalCredits;
    },
  },
  methods: {
    getCellStyle(_, columnIndex) {
      const colIndex = columnIndex - 1; // without index (#) column
      const styles = ['py-1'];
      if (
        this.columns.findIndex(({ key }) => key === 'actions') === colIndex ||
        this.columns.findIndex(({ key }) => key === TABLE_HEADERS.STATUS) === colIndex
      )
        styles.push('p-0');
      return styles.join(' ');
    },
    getSuppliersBankTransferPaymentMethod(imbalance) {
      return imbalance.supplierBankAccount
        ? {
            paymentMethod: PAYMENT_METHOD_TYPE.BANK_TRANSFER,
            destinationBankAccount: omit(
              ['__typename', 'id', 'businessId', 'filePath', 'filePathUrl', 'manualValidation'],
              reject(isNil, imbalance.supplierBankAccount)
            ),
          }
        : null;
    },
    handleHeaderActions({ command }) {
      switch (command) {
        case 'chooseAllPaymentMethod':
          this.updateAllPaymentMethod();
          break;
        case 'resetAllPaymentMethod':
          this.resetAllPaymentMethod();
          break;
        case 'choosePaymentDateToAll':
          this.quickActionDatePickerModal = true;
          break;
        case 'executeAccordingToPaymentTerms':
          this.updateAllPaymentDateByDueDate();
          break;
        case 'executeToday':
          this.updateAllPaymentDateToday();
          break;
        case 'resetAllPaymentDate':
          this.resetAllPaymentDate();
          break;
        case 'chooseAllFullPaymentAmount':
          this.updateAllFullPaymentAmount();
          break;
        case 'chooseAllFullPaymentAmountPlusCredits':
          this.updateAllFullPaymentAmountPlusCredits();
          break;
        case 'resetAllPaymentAmount':
          this.resetAllPaymentAmount();
          break;
        default:
          break;
      }
    },
    handlePaymentAmount({ action, rowIndex, billingsLeftToBePaid, reconciliationId, unpaidCredits }) {
      switch (action) {
        case 'fullAmountPlusCredits':
          this.updateFullPaymentAmountPlusCredits({ billingsLeftToBePaid, unpaidCredits, reconciliationId }, rowIndex);
          break;
        case 'fullAmount':
          this.updateFullPaymentAmount({ billingsLeftToBePaid, reconciliationId }, rowIndex);
          break;
        case 'define':
          this.selectedImbalancePaidBillingsIndex = rowIndex;
          break;
        default:
          break;
      }
    },
    handleBillingsToPaySelection(billingIdsAndAmounts) {
      this.updateBillingsPaid(billingIdsAndAmounts, this.selectedImbalancePaidBillingsIndex);
      this.selectedImbalancePaidBillingsIndex = null;
    },
    updateBillingsPaid(billingIdsAndAmounts, rowIndex) {
      if (this.imbalances[rowIndex].status === 'processed') return;
      this.imbalances[rowIndex].amountsToBePaid = billingIdsAndAmounts.map((b) => ({
        ...b,
        date: DateTime.fromJSDate(new Date(b.date)).toISODate(),
      }));
      this.selectedBillings = {
        ...this.selectedBillings,
        [this.imbalances[rowIndex].reconciliationId]: billingIdsAndAmounts.map(({ billingId }) => billingId),
        ...this.singleCredits,
      };
      const prop = `imbalances.${rowIndex}.amountsToBePaid`;
      const formField = this.$refs.form.fields.find((field) => field.prop === prop);
      if (formField.validateState) this.$refs.form.validateField(prop);

      if (!this.paymentsBalance[rowIndex]) {
        this.imbalances[rowIndex].paymentDate = new Date();
        this.updatePaymentMethod(null, rowIndex);
      }
    },
    getPaymentDate(paymentTerm, billingDate) {
      const billingDay = DateTime.fromISO(billingDate).get('day');
      const termDay = parseInt(paymentTerm.directDebitDay);
      let paymentDate = DateTime.fromISO(billingDate);

      if (paymentTerm.beforeBilling && billingDay < termDay) {
        paymentDate = paymentDate.minus({ months: 1 });
      }
      if (!paymentTerm.beforeBilling && billingDay > termDay) {
        paymentDate = paymentDate.plus({ months: 1 });
      }

      paymentDate = paymentDate.set({ day: termDay });
      if (paymentDate.day !== termDay) {
        paymentDate = paymentDate.minus({ months: 1 }).endOf('month').startOf('day');
      }

      return paymentDate.toJSDate();
    },
    handlePaymentMethodAdd(command) {
      const { action, rowIndex, creditCard, paymentTerm } = command;
      switch (action) {
        case 'choosePaymentMethod':
          if (paymentTerm?.directDebit) {
            const billingDate = this.imbalances[rowIndex].amountsToBePaid[0].date;
            this.imbalances[rowIndex].paymentDate = this.getPaymentDate(paymentTerm, billingDate);
            if (creditCard) {
              return this.updatePaymentMethod(
                {
                  paymentMethod: PAYMENT_METHOD_TYPE.CREDIT_CARD,
                  number: creditCard.number,
                  type: creditCard.type,
                  cardOwner: creditCard.cardOwner,
                  directDebit: true,
                },
                rowIndex
              );
            }

            if (paymentTerm?.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER) {
              return this.updatePaymentMethod(
                {
                  paymentMethod: PAYMENT_METHOD_TYPE.BANK_TRANSFER,
                  directDebit: true,
                },
                rowIndex
              );
            }
          } else
            this.updatePaymentMethod(this.getSuppliersBankTransferPaymentMethod(this.imbalances[rowIndex]), rowIndex);
          break;
        case 'addDocumentedPaymentMethod':
          this.selectedImbalanceTransactionIndex = rowIndex;
          break;
        case 'addPaymentMethod':
          this.selectedImbalancePaymentMethodIndex = rowIndex;
          break;
        default:
          break;
      }
    },
    handlePaymentMethodUpdate(paymentMethodData) {
      this.updatePaymentMethod(paymentMethodData, this.selectedImbalanceTransactionIndex);
      this.selectedImbalanceTransactionIndex = null;
    },
    handleAddPaymentMethod(data) {
      const { paymentMethod, bankAccount } = data;
      switch (paymentMethod) {
        case PAYMENT_METHOD_TYPE.BANK_TRANSFER:
          this.updatePaymentMethod(
            { paymentMethod, destinationBankAccount: bankAccount },
            this.selectedImbalancePaymentMethodIndex
          );
          break;
      }
      this.selectedImbalancePaymentMethodIndex = null;
    },
    updatePaymentMethod(paymentMethodData, rowIndex) {
      const today = DateTime.local().startOf('day').toJSDate();
      if (this.imbalances[rowIndex].status === 'processed') return;
      this.imbalances[rowIndex].transactions = paymentMethodData ? [paymentMethodData] : [];
      if (
        !this.isExecution &&
        paymentMethodData &&
        !paymentMethodData.directDebit &&
        this.imbalances[rowIndex].paymentDate > today
      )
        this.imbalances[rowIndex].paymentDate = null;
      const prop = `imbalances.${rowIndex}.transactions`;
      const formField = this.$refs.form.fields.find((field) => field.prop === prop);
      if (formField.validateState) this.$refs.form.validateField(prop);
    },
    updateFullPaymentAmount({ billingsLeftToBePaid, reconciliationId }, index) {
      const billingsToOmit = this.filterOutCurrentRecoSelectedBillings(reconciliationId);
      const billingIdsAndAmounts = billingsLeftToBePaid
        .filter(({ billingId }) => !billingsToOmit.includes(billingId))
        .map(({ billingId, balanceDue, date }) => ({
          billingId,
          amount: balanceDue,
          date,
        }));
      this.updateBillingsPaid(billingIdsAndAmounts, index);
    },
    updateFullPaymentAmountPlusCredits({ unpaidCredits, billingsLeftToBePaid, reconciliationId }, index) {
      const creditIdsAndAmounts = unpaidCredits.map(({ billingId, unpaidAmount, date }) => ({
        billingId,
        amount: unpaidAmount,
        date,
      }));
      const billingsToOmit = this.filterOutCurrentRecoSelectedBillings(reconciliationId);
      const billingIdsAndAmounts = billingsLeftToBePaid
        .filter(({ billingId }) => !billingsToOmit.includes(billingId))
        .map(({ billingId, balanceDue, date }) => ({
          billingId,
          amount: balanceDue,
          date,
        }));
      this.updateBillingsPaid(billingIdsAndAmounts.concat(creditIdsAndAmounts), index);
    },
    updateAllFullPaymentAmount() {
      this.selectedBillings = this.singleCredits;
      this.imbalances.forEach(this.updateFullPaymentAmount);
    },
    updateAllFullPaymentAmountPlusCredits() {
      this.selectedBillings = this.singleCredits;
      this.imbalances.forEach(({ supplier, billingsLeftToBePaid, reconciliationId }, index) => {
        if (this.isSupplierImbalanceSingle(supplier.id)) {
          const unpaidCredits = this.unpaidCreditsBySupplier[supplier.id] ?? [];
          this.updateFullPaymentAmountPlusCredits({ unpaidCredits, billingsLeftToBePaid, reconciliationId }, index);
        }
      });
    },
    resetAllPaymentAmount() {
      for (const imbalance of this.imbalances) {
        if (imbalance.status !== 'processed') {
          imbalance.amountsToBePaid = [];
        }
      }
      this.selectedBillings = this.singleCredits;
    },
    updateAllPaymentMethod() {
      this.imbalances.forEach((imbalance, index) => {
        if (this.paymentsBalance[index]) {
          const bankTransferPaymentMethod = this.getSuppliersBankTransferPaymentMethod(imbalance);
          this.updatePaymentMethod(bankTransferPaymentMethod, index);
        }
      });
    },
    resetAllPaymentMethod() {
      this.imbalances.forEach((_, index) => {
        if (this.paymentsBalance[index]) {
          this.updatePaymentMethod(null, index);
        }
      });
    },
    updateAllPaymentDate(date) {
      this.quickActionDatePickerModal = false;

      for (let i = 0; i < this.imbalances.length; i++) {
        if (this.imbalances[i].status !== 'processed' && !!this.paymentsBalance[i])
          this.imbalances[i].paymentDate = date;
        else if (this.imbalances[i].status === 'processed' && !this.paymentsBalance[i])
          this.imbalances[i].paymentDate = null;
      }
    },
    updateAllPaymentDateByDueDate() {
      for (let i = 0; i < this.imbalances.length; i++) {
        if (
          this.imbalances[i].status !== 'processed' &&
          this.imbalances[i].paymentDueDate > new Date() &&
          !!this.paymentsBalance[i]
        )
          this.imbalances[i].paymentDate = this.imbalances[i].paymentDueDate;
        else if (!this.paymentsBalance[i]) {
          this.imbalances[i].paymentDate = null;
        }
      }
    },
    updateAllPaymentDateToday() {
      for (let i = 0; i < this.imbalances.length; i++) {
        if (this.imbalances[i].status !== 'processed' && !!this.paymentsBalance[i])
          this.imbalances[i].paymentDate = new Date();
        else if (this.imbalances[i].status === 'processed' && !this.paymentsBalance[i])
          this.imbalances[i].paymentDate = null;
      }
    },
    resetAllPaymentDate() {
      for (let i = 0; i < this.imbalances.length; i++) {
        if (this.imbalances[i].status !== 'processed' && !!this.paymentsBalance[i])
          this.imbalances[i].paymentDate = null;
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs.form.validate().catch((err) => err);
      if (!valid) return;

      const groupedPayments = groupBy(({ supplier, paymentDate, transactions }) => {
        const normalizeDate = (dateString) => DateTime.fromJSDate(new Date(dateString)).startOf('day').toMillis();

        if (transactions.length) {
          return `${supplier.id}_${normalizeDate(paymentDate)}_${transactions[0].paymentMethod}_${JSON.stringify(
            transactions[0]
          )}`;
        }

        return `${supplier.id}_${paymentDate.getTime()}_NoPaymentMethod_${JSON.stringify(transactions[0])}`;
      })(this.imbalances);

      const getImbalanceIndex = (payment) =>
        this.imbalances.findIndex(({ reconciliationId }) => reconciliationId === payment.reconciliationId);

      const combinedPayments = flatten(
        Object.values(groupedPayments).map((payments) =>
          payments.reduce(
            (combinedPayment, payment) =>
              isEmpty(combinedPayment)
                ? { ...payment, imbalanceIndices: [getImbalanceIndex(payment)] }
                : {
                    ...combinedPayment,
                    amountsToBePaid: combinedPayment.amountsToBePaid.concat(payment.amountsToBePaid),
                    imbalanceIndices: [...combinedPayment.imbalanceIndices, getImbalanceIndex(payment)],
                  },
            {}
          )
        )
      ).map((p) => ({
        ...p,
        totalPaid: p.amountsToBePaid.reduce((sum, { amount }) => sum.add(amount), new Big(0)).toNumber(),
      }));

      if (this.isExecution && combinedPayments.some((payment) => payment.totalPaid < 0)) {
        this.$alert(
          this.$t('payment.paymentOperationModal.paymentNotAllowedDescription'),
          this.$t('payment.paymentOperationModal.paymentNotAllowedTitle'),
          {
            confirmButtonText: this.$t('commons.close'),
            confirmButtonClass: 'el-button--secondary',
            showClose: false,
          }
        );
        return;
      }

      if (this.isExecution) {
        const actualPaymentsToBeMade = combinedPayments.filter(({ totalPaid }) => !!totalPaid);
        const confirmed = await this.$confirm(
          this.$tc(
            actualPaymentsToBeMade.length > 0
              ? `payment.paymentTable.paymentInstructionApprovalText${this.currentTenant.countryCode}`
              : `payment.paymentTable.paymentBalancedInstructionApprovalText`,
            actualPaymentsToBeMade.length,
            {
              count: actualPaymentsToBeMade.length,
              dangerouslyUseHTMLString: true,
            }
          ),
          this.$t(
            actualPaymentsToBeMade.length > 0
              ? `payment.paymentTable.paymentInstructionApprovalTitle${this.currentTenant.countryCode}`
              : `payment.paymentTable.paymentBalancedInstructionApprovalTitle`
          ),
          {
            showClose: false,
            cancelButtonClass: 'el-button--secondary',
            dangerouslyUseHTMLString: true,
          }
        ).catch(() => {});

        if (!confirmed) return;
      }

      const isBalanced = (payment) => {
        const amounts = map((paymentToBePaid) => new Big(paymentToBePaid.amount), payment.amountsToBePaid);
        const totalPaymentsSum = reduce((acc, amount) => acc.plus(amount), new Big(0), amounts).toNumber();
        return totalPaymentsSum === 0;
      };

      const getTypeDetails = (payment) => {
        if (isBalanced(payment)) {
          return {
            type: 'balance',
            initialBalance: false,
          };
        }

        if (this.isExecution) {
          return {
            type: 'managed',
          };
        }

        return {
          type: 'documentation',
        };
      };

      const getDateDetails = (payment) => {
        if (isBalanced(payment) || !this.isExecution) {
          return {
            date: DateTime.fromJSDate(payment.paymentDate).toISODate(),
            requestedDate: null,
          };
        }

        if (this.isExecution) {
          return {
            requestedDate: DateTime.fromJSDate(payment.paymentDate).toISODate(),
            date: null,
          };
        }
      };

      const loading = this.$loading();
      const results = await Promise.allSettled(
        combinedPayments
          .filter(({ status }) => status !== 'processed')
          .map((payment) =>
            this.createPayment({
              createParams: reject(isNil)({
                ...getTypeDetails(payment),
                ...getDateDetails(payment),
                supplierId: payment.supplier.id,
                businessId: this.currentTenant.id,
                customerId: this.currentTenant.id,
                amount: Math.abs(payment.totalPaid),
                isRefund: payment.totalPaid < 0,

                transactions: payment.transactions,
                billingLinks: payment.amountsToBePaid,
              }),
            })
          )
      );
      loading.close();

      const responseStatuses = this.imbalances
        .filter(({ status }) => status !== 'processed')
        .reduce((acc, imbalance, index) => {
          const getImbalanceStatus = (result) => {
            const conflict = result.reason?.graphQLErrors?.find((error) => error?.extensions?.response?.status === 409);
            if (!isNil(conflict)) {
              return 'conflict';
            } else if (result.status === 'rejected') {
              return 'failed';
            }
            return 'processed';
          };
          const resultIndex = combinedPayments.findIndex(({ imbalanceIndices }) => imbalanceIndices.includes(index));
          imbalance.status = getImbalanceStatus(results[resultIndex]);
          acc.push(imbalance.status);
          return acc;
        }, []);

      const failedPayments = responseStatuses.filter((status) => {
        return status !== 'processed';
      });

      if (failedPayments.length) {
        return this.$message.error(
          this.$t(
            `payment.paymentOperationModal.${
              this.isExecution ? 'paymentToExecuteCreateErrorMessage' : 'paymentCreateErrorMessage'
            }`,
            {
              failedCount: failedPayments.length,
              totalCount: results.length,
            }
          )
        );
      }
      if (!this.isExecution) {
        this.$message.success(
          this.$tc('payment.paymentOperationModal.paymentDocumentationCreateSuccessMessage', combinedPayments.length)
        );
        this.$emit('close');
      } else {
        this.$message.success(this.$t('payment.paymentOperationModal.paymentInstructionCreateSuccessMessage'));
        this.$emit('close', this.imbalances.length);
      }
    },
    close() {
      this.$confirm(
        this.$t('commons.cancellation.cancellationMessage'),
        this.$t('commons.cancellation.processCancellation'),
        {
          showClose: false,
          confirmButtonText: this.$t('commons.cancellation.cancelProcess'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: this.$t('commons.cancellation.continueProcess'),
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(() => this.$emit('close'))
        .catch(() => {});
    },
    getDatePickerOptions(paymentDueDate, transaction) {
      const today = DateTime.local().startOf('day').toJSDate();
      const datePickerShortcuts = reject(isNil)([
        {
          text: this.$t('payment.paymentOperationModal.table.data.today'),
          onClick(picker) {
            picker.$emit('pick', today);
          },
        },
        (this.isExecution && paymentDueDate >= today) || (!this.isExecution && paymentDueDate <= today)
          ? {
              text: this.$t('payment.paymentOperationModal.table.headers.accordingToPaymentTerms'),
              onClick(picker) {
                picker.$emit('pick', paymentDueDate);
              },
            }
          : null,
      ]);
      return {
        disabledDate: (date) => (this.isExecution ? date < today : date > today && !transaction?.directDebit),
        shortcuts: datePickerShortcuts,
      };
    },
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    async handleAction(command, rowIndex) {
      if (command === 'removeRow') {
        this.updateSelectedBillings(this.imbalances[rowIndex].reconciliationId);
        this.imbalances.splice(rowIndex, 1);
        const validationHasActivated = this.$refs.form.fields.find((field) => field.validateState === 'error');
        if (validationHasActivated) {
          await new Promise((resolve) => this.timeoutIds.push(setTimeout(resolve, 500)));
          await this.$refs.form.validate().catch((err) => err);
        }
      }
    },
    updateSelectedBillings(reconciliationId) {
      const updatedSelectedBillings = { ...this.selectedBillings };
      delete updatedSelectedBillings[reconciliationId];
      this.selectedBillings = updatedSelectedBillings;
    },
    isSupplierImbalanceSingle(supplierId) {
      return this.imbalances.filter((imbalance) => imbalance.supplier.id === supplierId).length === 1;
    },
    canShowPaymentMethodQuickAction(amounts, paymentTerm) {
      return (
        amounts.length === 1 &&
        paymentTerm &&
        paymentTerm.directDebit &&
        (paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.CREDIT_CARD ||
          paymentTerm.paymentMethod === PAYMENT_METHOD_TYPE.BANK_TRANSFER)
      );
    },
    fullAmountPlusCreditsFromReconciliationMonthText({ billingsLeftToBePaid, reconciliationId }) {
      const billingsToOmit = this.filterOutCurrentRecoSelectedBillings(reconciliationId);
      const currentCredits = billingsLeftToBePaid.filter(
        ({ billingId, balanceDue }) => balanceDue < 0 && !billingsToOmit.includes(billingId)
      );
      const sumCurrentCredits = currentCredits.reduce((sum, { balanceDue }) => sum + balanceDue, 0);
      const billingsLeftToBePaidWithoutCredits = billingsLeftToBePaid.reduce(
        (sum, { balanceDue }) => (balanceDue > 0 ? sum + balanceDue : sum),
        0
      );
      if (sumCurrentCredits === 0) {
        return this.$t('payment.paymentOperationModal.table.fullAmount', {
          amount: this.formatMoney(billingsLeftToBePaidWithoutCredits),
        });
      }

      const months = uniq(
        currentCredits.map(({ date }) => new Date(date).toLocaleString(this.$i18n.locale, { month: 'short' }))
      );

      const text = this.$tc('payment.paymentOperationModal.table.fullAmountPlusCreditsFromMonths', months.length, {
        debit: this.formatMoney(billingsLeftToBePaidWithoutCredits),
        credit: this.formatMoney(sumCurrentCredits),
        months: months.join(', '),
      });
      return text;
    },
    fullAmountPlusCreditsFromMonthsText({ unpaidCredits, billingsLeftToBePaid, reconciliationId }) {
      const billingsToOmit = this.filterOutCurrentRecoSelectedBillings(reconciliationId);
      const currentCredits = billingsLeftToBePaid.filter(
        ({ balanceDue, billingId }) => balanceDue < 0 && !billingsToOmit.includes(billingId)
      );
      const sumCurrentCredits = currentCredits.reduce((sum, { balanceDue }) => sum + balanceDue, 0);
      const billingsLeftToBePaidWithoutCredits = billingsLeftToBePaid.reduce(
        (sum, { balanceDue }) => (balanceDue > 0 ? sum + balanceDue : sum),
        0
      );

      const months = uniq(
        [...unpaidCredits, ...currentCredits].map(({ date }) =>
          new Date(date).toLocaleString(this.$i18n.locale, { month: 'short' })
        )
      );
      const text = this.$tc('payment.paymentOperationModal.table.fullAmountPlusCreditsFromMonths', months.length, {
        debit: this.formatMoney(billingsLeftToBePaidWithoutCredits),
        credit: this.formatMoney(
          sumCurrentCredits + unpaidCredits.reduce((sum, { unpaidAmount }) => sum + unpaidAmount, 0)
        ),
        months: months.join(', '),
      });
      return text;
    },
    filterOutCurrentRecoSelectedBillings(reconciliationId) {
      return pipe(omit([reconciliationId]), values, flatten)(this.selectedBillings);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

$header-height: 3.75rem;
$footer-height: 4.875rem;
$body-height: calc(100% - #{$header-height + $footer-height});

:deep(.payment-operation-modal) {
  height: 80vh;

  > .el-dialog__header {
    height: $header-height;
  }
  > .el-dialog__body {
    height: $body-height;
  }
  > .el-dialog__footer {
    height: $footer-height;
  }

  > .darker-mask {
    background: change-color($color: $black, $alpha: 0.4);
  }
}

:deep(.el-form-item__content) {
  line-height: 1.5;
}

.payment-date :deep(.el-form-item__content) {
  margin-inline-end: 16px;
}

.payment-date-picker {
  width: 100% !important;
}

.payment-operation-table {
  .remove-action .active {
    visibility: visible;
  }

  th {
    z-index: 1;
  }

  tr {
    .remove-action {
      visibility: hidden;
    }

    &:hover .remove-action {
      visibility: visible;
    }
  }
}

.is-error button {
  color: $error;
}
</style>
<style lang="scss">
@import '@/stylesheets/scss/global';

.custom-tooltip {
  max-width: 308px;
  white-space: normal;
  word-wrap: break-word;
  font-size: $font-size-small;
}

html[dir='ltr'] .custom-tooltip {
  text-align: left;
}

html[dir='rtl'] .custom-tooltip {
  text-align: right;
}
</style>
